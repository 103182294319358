
@mixin rounded($radius){

    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
  }
  @mixin shadow($x, $y, $blur, $color) {
    -webkit-box-shadow: $x $y $blur $color;
    -moz-box-shadow: $x $y $blur $color;
    box-shadow: $x $y $blur $color;
  }
  
  @mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms
  }
  
  @mixin transition($transitions){
    -webkit-transition: $transitions;
    -moz-transition: $transitions;
    -ms-transform: $transitions;
    -o-transition: $transitions;
    transition: $transitions;
  }
  
  @mixin animation($animations){
    -webkit-animation: $animations;
    -moz-animation: $animations;
    -ms-animation: $animations;
    -o-animation: $animations;
    animation: $animations;
  }
  
  
  @mixin responsive($media) {
    @if $media == movil {
      @media only screen and (min-width: $movil) { @content; }
    }
    @else if $media == tablet {
      @media only screen and (min-width: $tablet) { @content; }
    }
    @else if $media == ordenador {
      @media only screen and (min-width: $pc) { @content; }
    }
    @else if $media == pantalla {
      @media only screen and (min-width: $pantalla) { @content; }
    }
  }
  
  @mixin nomobile(){
    @include responsive(movil) { display:none; }
    @include responsive(tablet) { display:block; }
  }
  
  @mixin notablet(){
    @include responsive(movil) { display:none; }
    @include responsive(tablet) { display:none; }
    @include responsive(ordenador) { display:block; }
  }
  @mixin noordenador(){
    @include responsive(movil) { display:none; }
    @include responsive(tablet) { display:none; }
    @include responsive(ordenador) { display:none; }
    @include responsive(pantalla) { display:block; }
  }
  
  