
footer{
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
    p{
      font-size: 15px;
      margin: 0px 10px 10px;
      span,a {
        margin: 10px 10px 0px 0;
        float: left;
        clear: both;
        font-weight: 400;
        color: $negro;
        @include responsive(tablet) {
          font-weight: 300;
        }
  
        &:after {
          content: none;
        }
  
        @include responsive(tablet){
          margin: 0px 10px 0px 0;
          float: none;clear: inherit;
  
          &:after {
            margin-left: 10px;
            content: '|';
          }
        }
  
      }
  
  
      a{
        &:last-child{
          &:after {
            content: none;
          }
        }
      }
  
    }
  }