
$movil: 320px;
$tablet: 768px;
$pc: 992px;
$pantalla: 1200px;

$gris: #393e46;
$negro: #222831;
$blanco: white;
$enlace: #29a19c;
$enlace_hover: #a3f7bf;