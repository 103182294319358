
.contenido{
    .sidebar {
        text-align: left;
        h2 {
            text-align: left;
        }
        .h3 {
            text-align: left;
        }
    }

    .h3 {
        text-align: left;
    }
   
    &__text{
        margin: 0px 0 50px;
        text-align: left;

        a{
            font-weight: 500;
            font-family: 'Lora', sans-serif;
        }
       
        .h2,h2 {
            text-align: left;
            line-height: 26px;
            margin-bottom: 10px;
            font-size: 20px;
            letter-spacing: 0.1px;
        }
    }
}