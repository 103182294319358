
.container {
    width: 100%;
    @include responsive(movil) { width: 100%;}
    @include responsive(tablet) { max-width: 930px; }
    @include responsive(ordenador) { max-width: 1000px; }
    @include responsive(pantalla) { max-width: 1170px; }
}

.browsehappy {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

.img-rounded{
    margin: 0 auto;
    @include rounded(100px);
}


.nomobile{
    @include responsive(movil) { display:none; }
    @include responsive(tablet) { display:block; }
    @include responsive(ordenador) { max-width: 1000px; }
    @include responsive(ordenador) { max-width: 1170px; }
}

.text {
    &__title {
        font-size: 25px;
        line-height: 31px;
        font-family: 'Oswald', sans-serif;
    }
    &__minititle {
        font-family: Lora,serif;
        margin: 0 0 15px;
        font-weight: 400;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
}

nav {
  ul + div {
      margin-top: 30px;
  }  
}