
.header-top{
    font-size:13px;
    font-weight: 300;
  
    @include nomobile();
  
    .h3{
      font-family: 'Oswald', sans-serif;
      margin: 0px;
      letter-spacing: 2px;
      font-weight: 600;
      font-size: 36px;
      padding: 0;
    }
  
    .col-md-6{
      text-align: right;
      &:first-child{
        text-align: left;
      }
      &.segundo{
        padding-top: 15px;
        p{
          color:$gris;
          font-size: 14px;
        }
        a{
          margin-left:10px;
          color:$gris;
          font-size: 14px;
          &:hover {
            color: $enlace;
          } 
        }
      }
    }  
  }


.header{
    margin-top:50px;
    margin-bottom: 50px;
    h2 {
      text-align: center;
    }
    .col-md-12{
      text-align:center;  
      ul{
        margin: 20px auto 0;
        padding: 0;
        list-style-type: none;
        li{
          display: inline-block;
          list-style-type: none;
          margin: 10px 0px;
          width: 45%;
  
          @include responsive(movil){
            margin: 10px 6px;
            width:auto;
          }
          @include responsive(tablet){
            margin: 10px 10px;
          }
  
          i{
            font-size: 21px;
            color:$negro;
          }
          h3{
            text-align:center;
          }
        }
      }
    }
  
  }
  