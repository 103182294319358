body{
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  background-color: $blanco;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: 'Oswald', sans-serif;
  color: inherit;
}

.h1,h1{
  font-family: 'Oswald', sans-serif;

  text-align: center;
  margin: 20px auto;
  letter-spacing: -0.5px;
  font-weight: bold;
  font-size: 40px;
  

  @include responsive(tablet){
    font-size: 45px;
  }

}

h2{
  font-family: 'Oswald', sans-serif;
  text-align: left;
  margin: 0px auto 32px;
  letter-spacing: -0.5px;
  font-weight: 600;


  font-size: 30px;
  line-height: 41px;

  @include responsive(tablet){
    text-align: center;
    font-size: 25px;
    line-height: 21px;
}

  &.seg_head{
    margin: 30px auto;
    font-size: 30px;
    letter-spacing: 0px;
  }
}

.h3,h3{
  font-family: Lora,serif;
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 6px 0;
}

p{
  font-size: 16px;
  color: $gris;
  font-weight: 100;
  font-family: 'Lora', sans-serif;

}

a{
  @include transition(all .3s);
  color: $gris;
  opacity:1;
  font-weight: 400;
  @include responsive(tablet) {
    font-weight: 300;
  }

  &:hover{
    color: $enlace;
    text-decoration: none;
  }

  p & {
    color: $enlace;
    &:hover {
      color: $enlace_hover;
    }
  }

}

:focus,
:visited,
:active{
  outline: none;
  text-decoration: none;
}

  
img{
    &.img-responsive {
      margin-top: 55px;
      @include responsive(movil) { margin-bottom: 30px;}
    }
    &.cover{
      margin: 0px auto 55px;
      width: 100%;
    }
  }
  